// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { handleLogout } from "./redux/actions/auth";
import { handleOrg } from "./redux/actions/layout";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ErrorBoundary from "./ErrorBoundary";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
Bugsnag.start({
  apiKey: "50add759782857c041ff0610dabb4027",
  plugins: [new BugsnagPluginReact()],
});
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
const organizationId = localStorage.getItem("orgId");
if (organizationId) {
  store.dispatch(handleOrg(organizationId));
} else {
  store.dispatch(handleLogout());
}
const BugsnagBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
ReactDOM.render(
  <BugsnagBoundary>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <ErrorBoundary>
            <IntlProviderWrapper>
              <LazyApp />
            </IntlProviderWrapper>
          </ErrorBoundary>
          <ToastContainer newestOnTop autoClose={2000} />
        </ThemeContext>
      </Suspense>
    </Provider>
  </BugsnagBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
